<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getDropDownElementsByTypeApi } from "@/api/common";
import { fetchSettings } from "@/api/common";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  page: {
    title: "Paramètres généraux",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader , ckeditor: CKEditor.component},
  import: { getDropDownElementsByTypeApi, fetchSettings },
  
  validations: {
    companySettingsForm: {
      company_name: { required, minLength:minLength(3) },
      company_email: { required, email },
      company_phone: { required, numeric },
    },
    reportsSettingsForm: {
      report_paper_head: { required },
      report_paper_foot: { required },
      // : { required }
    },
  },
  mounted() {
    this.populateCompanySettings();
  },
  methods: {
    showCompanyLogo(configs) {
      this.imagePreview = configs.company_logo;
      this.showPreview = true;
    },
    populateCompanySettings() {
      fetchSettings()
        .then(({ data }) => {
          var configs = data.original.data;
          this.companySettingsForm.company_name = configs.company_name;
          this.companySettingsForm.company_phone = configs.company_phone;
          this.companySettingsForm.company_email = configs.company_email;
          this.companySettingsForm.company_website = configs.company_website;
          this.companySettingsForm.company_adr = configs.company_adr;
          /// REPORT
          this.reportsSettingsForm.report_paper_head = configs.report_paper_head;
          this.reportsSettingsForm.report_paper_foot = configs.report_paper_foot;
          if (configs.company_logo != null && configs.company_logo != "#") {
            this.showCompanyLogo(configs);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    onLogoSelectChange(event) {
      this.companySettingsForm.company_logo = event.target.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function() {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this),
        false
      );

      if (this.companySettingsForm.company_logo) {
        if (
          /\.(jpe?g|png|gif)$/i.test(this.companySettingsForm.company_logo.name)
        ) {
          reader.readAsDataURL(this.companySettingsForm.company_logo);
        }
      }
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.reportsSettingsForm.$invalid) {
        var loader = this.$loading.show();

          this.$http
            .post("/system/settings/store", this.reportsSettingsForm)
    
            .then((res) => {
              var status = res.data.original.status;
              loader.hide();
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.populateCompanySettings();
                  break;
    
                case 500:
                  this.$toast.success(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.success(error.message);
            })
            .finally(() => {});
      }else{
        this.$toast.error("Veuillez vérifier le formulaire");
      }
    },

    submitCompanySettings() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.companySettingsForm.$invalid) {
        let formData = new FormData();
        formData.append("company_name", this.companySettingsForm.company_name);
        formData.append(
          "company_phone",
          this.companySettingsForm.company_phone
        );
        formData.append(
          "company_email",
          this.companySettingsForm.company_email
        );
        formData.append(
          "company_website",
          this.companySettingsForm.company_website
        );
        formData.append("company_adr", this.companySettingsForm.company_adr);
        formData.append("company_logo", this.companySettingsForm.company_logo);
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", formData)

          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                // setTimeout(function () {
                //   router.push({ name: "base.contracts.contracts.index" });
                // }, 3000);
                this.populateCompanySettings();
                break;

              case 500:
                loader.hide();

                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();

            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
  },
  data() {
    return {
      title: "Paramètres généraux",
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",

      plugins: [
        "advlist autolink link lists charmap hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars nonbreaking",
        "save contextmenu directionality template paste textcolor",
      ],
      toolbar:
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons",
  reportsSettingsForm: {
        report_paper_head: "",
        report_paper_foot: "",
      },
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      companySettingsForm: {
        company_name: "",
        company_phone: "",
        company_email: "",
        company_website: "",
        company_adr: "",
        company_logo: null,
      },
      imagePreview: null,
      showPreview: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          to: {name: 'base.settings.index'},
        },
        {
          text: "Paramètres généraux",
          active: true,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab title="Entreprise" active title-item-class="mb-2">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="">
                      <div class="">
                        <form
                          class="needs-validation"
                          @submit.prevent="submitCompanySettings"
                          enctype="multipart/form-data"
                        >
                          <div class="row">
                            <div class="col-md-8">
                              <div class="row">
                                <div class="col-lg-3">
                                  <div class="form-group">
                                    <label for="formrow-inputCity"
                                      >Nom d'entreprise *</label
                                    >
                                    <input
                                      type="text"
                                      v-model="companySettingsForm.company_name"
                                      :class="{
                                        'is-invalid':
                                            submitted && $v.companySettingsForm.company_name.$error,
                                        }"
                                      class="form-control"
                                    />
                                    <div v-if="submitted && $v.companySettingsForm.company_name.$error"
                                        class="invalid-feedback">
                                        <span v-if="!$v.companySettingsForm.company_name.required">Champs obligatoire.</span>
                                        <span v-if="!$v.companySettingsForm.company_name.minLength">Le nom d'entreprise doit comprendre plus de 3 caractères.</span>
                                        <br />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-3">
                                  <div class="form-group">
                                    <label for="formrow-inputCity"
                                      >Email *</label
                                    >
                                    <input
                                      type="text"
                                      v-model="
                                        companySettingsForm.company_email
                                      "
                                      :class="{
                                        'is-invalid':
                                            submitted && $v.companySettingsForm.company_email.$error,
                                        }"
                                      class="form-control"
                                    />
                                    <div v-if="submitted && $v.companySettingsForm.company_email.$error"
                                        class="invalid-feedback">
                                        <span v-if="!$v.companySettingsForm.company_email.required">Champs obligatoire.</span>
                                        <span v-if="!$v.companySettingsForm.company_email.email">Email Invalide.</span>
                                        <br />

                                        
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="formrow-password-input"
                                      >N° Telephone</label
                                    >
                                    <input
                                      type="text"
                                      v-model="
                                        companySettingsForm.company_phone
                                      "
                                      :class="{
                                        'is-invalid':
                                            submitted && $v.companySettingsForm.company_phone.$error,
                                        }"
                                      class="form-control"
                                    />
                                    <div v-if="submitted && $v.companySettingsForm.company_phone.$error"
                                        class="invalid-feedback">
                                        <span v-if="!$v.companySettingsForm.company_phone.required">Champs obligatoire.</span>
                                        <span v-if="!$v.companySettingsForm.company_phone.numeric">Champ Invalide.</span>                                        
                                        <br />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-3">
                                  <div class="form-group">
                                    <label for="formrow-inputCity"
                                      >Site web</label
                                    >
                                    <input
                                      type="text"
                                      v-model="
                                        companySettingsForm.company_website
                                      "
                                      class="form-control"
                                    />
                                    matchUrl
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="formrow-inputCity"
                                      >Adresse *</label
                                    >
                                    <textarea
                                      v-model="companySettingsForm.company_adr"
                                      class="form-control"
                                      cols="30"
                                      rows="4"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Image/Logo </label
                                >
                                <input
                                  class="form-control"
                                  type="file"
                                  @change="onLogoSelectChange"
                                  accept="image/*"
                                />
                                <img
                                  v-bind:src="imagePreview"
                                  width="100"
                                  height="100"
                                  v-show="showPreview"
                                />
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div class="row">
                            <div class="col-md-12">
                              <button class="btn btn-primary float-right">
                                <i class="fas fa-save"></i> Enregistrer
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Etats de sortie" title-item-class="mb-2">
                <form
              class="needs-validation"
              @submit.prevent="formSubmit"
              enctype="multipart/form-data"
            >
              
              <div class="row">
                <div class="col-12">
                  <div class="">
                    <div class="">
                      <h4 :class="{ 'text-danger': submitted && $v.reportsSettingsForm.report_paper_head.$error }">Entête de page *</h4>
                      <p class="">
                        Cette partie sera affichée comme <b>entête de page</b> sur tous les rapports.
                      </p>
                      <!-- Editor -->
                      <ckeditor
                        :class="{
                          'is-invalid':
                              submitted && $v.reportsSettingsForm.report_paper_head.$error,
                          }"
                        v-model="reportsSettingsForm.report_paper_head"
                        :editor="editor"
                      ></ckeditor>
                      <div v-if="submitted && $v.reportsSettingsForm.report_paper_head.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.reportsSettingsForm.report_paper_head.required">Champs obligatoire.</span>
                          <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="">
                    <div class="">
                      <h4 :class="{ 'text-danger': submitted && $v.reportsSettingsForm.report_paper_foot.$error }">Pied de page *</h4>
                      <p class="card-title-desc">
                        Cette partie sera affichée comme <b>pied de page</b>   sur tous les rapports.
                      </p>
                      <!-- Editor -->
                      <ckeditor
                        v-model="reportsSettingsForm.report_paper_foot"
                        :editor="editor"
                        :class="{
                          'is-invalid':
                              submitted && $v.reportsSettingsForm.report_paper_foot.$error,
                          }"
                      ></ckeditor>
                      <div v-if="submitted && $v.reportsSettingsForm.report_paper_foot.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.reportsSettingsForm.report_paper_foot.required">Champs obligatoire.</span>
                          <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-primary float-right">
                    <i class="fas fa-save"></i> Enregistrer
                  </button>
                </div>
              </div>
            </form>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
